import React, {Component} from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { errorInfo: null, error: null };
    }
  

    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      this.setState({error: error, errorInfo: errorInfo})
    }
  
    render() {
        if (this.state.errorInfo) {
            return (
              <div>
                <h1 style={{ textAlign: "center" }}> Something went wrong</h1>
                <details style={{ whiteSpace: "pre-wrap", marginLeft: ".5rem" }}>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </details>
              </div>
            );
          }
  
      return this.props.children; 
    }
  }
  export default ErrorBoundary;