import React from "react";
import {
  SliceMenuContainer,
  CloseMenu,
  CloseMenuContainer,
  LinkButton,
  ButtonContainer,
} from "./slicemenu-style-component";
import { changeMenuOpen } from "../../redux/stateSlice/showPageStateSlice";
import { useDispatch } from "react-redux";
import MenuIcons from "../menu_icons/MenuIcons";

/**************************
 * SliceMenu component
 * changeCurrentName change the state in redux
 * redux pass new state onto Main.js
 * mobile divice menu is located in Navigation.js
 **************************/
function SliceMenu({ disabled }) {
  const dispatch = useDispatch();

  function onClickMenuOpen() {
    dispatch(changeMenuOpen({ currenStateOpen: false }));
  }

  return (
    <React.Fragment>
      <SliceMenuContainer>
        <CloseMenuContainer>
          <CloseMenu onClick={onClickMenuOpen} />
        </CloseMenuContainer>

        <LinkButton
          to={"/"}
          color={"black"}
          colorhover={"white"}
          disabled={disabled}
          onClick={onClickMenuOpen}
        >
          <ButtonContainer>Home</ButtonContainer>
        </LinkButton>

        <LinkButton
          to={"reactblog"}
          color={"black"}
          colorhover={"#6D4092"}
          onClick={onClickMenuOpen}
        >
          <MenuIcons reac={true}/>
        </LinkButton>

        <LinkButton
          to={"javascriptblog"}
          color={"black"}
          colorhover={"yellow"}
          onClick={onClickMenuOpen}
        >
          <MenuIcons javaScript={true}/>
        </LinkButton>

        <LinkButton
          to={"javablog"}
          color={"black"}
          colorhover={"#854747"}
          onClick={onClickMenuOpen}
        >
          <MenuIcons java={true}/>
        </LinkButton>
      </SliceMenuContainer>
    </React.Fragment>
  );
}
export default SliceMenu;
