import React from "react";
import {
  NavigationContainer,
  ButtonNavigationSubject,
  TitleNavigation,
  ButtonNavigationContainer,
  NavigationMenuForMobileDevices,
  One,
  Two,
  Three,
} from "./navigation-style-component";
import { BackDropMenu } from "../main/main-style-component";
import {
  selectcurrentMobileOpen,
  selectcurrentMenuOpen,
  changeMenuOpen,
  changeMobileOpen,
} from "../../redux/stateSlice/showPageStateSlice";
import { useSelector, useDispatch } from "react-redux";
import { useSpring, animated } from "@react-spring/web";
import SliceMenu from "../sliceMenu/SliceMenu";
import {
  BackgroundNavigationMobile,
  MobileMenuContainer,
} from "./navigation-style-component";
import {
  LinkButton,
  ButtonContainer,
} from "../sliceMenu/slicemenu-style-component";
import MenuIcons from "../menu_icons/MenuIcons";
/**********************
 * Navigation component
 * Navigation controls the menu for mobile devices
 *********************/
function Navigation() {
  const stateMobileMenuOpen = useSelector(selectcurrentMobileOpen);
  const stateMenuOpen = useSelector(selectcurrentMenuOpen);
  const dispatch = useDispatch();

  function onClickHandlerMenuOpen() {
    stateMenuOpen
      ? dispatch(changeMenuOpen({ currentStateOpen: false }))
      : dispatch(changeMenuOpen({ currentStateOpen: true }));
  }

  function onClickHandlerMobileMenuOpen() {
    stateMobileMenuOpen
      ? dispatch(changeMobileOpen({ currentStateMobileOpen: false }))
      : dispatch(changeMobileOpen({ currentStateMobileOpen: true }));
  }

  // animation for slide menu
  const slideanimation = useSpring({
    config: {
      duration: 250,
    },
    opacity: stateMenuOpen ? 1 : 0,
    transform: stateMenuOpen ? "translateX(60%)" : "translateX(100%)",
    width: "100%",
    height: "100%",
    background: "white",
  });

  return (
    <>
      <NavigationContainer>
        <TitleNavigation>The Tech Blog</TitleNavigation>
        <ButtonNavigationContainer>
          <ButtonNavigationSubject onClick={onClickHandlerMenuOpen}>
            Menu
          </ButtonNavigationSubject>
        </ButtonNavigationContainer>

        <NavigationMenuForMobileDevices
          open={stateMobileMenuOpen}
          onClick={onClickHandlerMobileMenuOpen}
        >
          <One open={stateMobileMenuOpen} />
          <Two open={stateMobileMenuOpen} />
          <Three open={stateMobileMenuOpen} />
        </NavigationMenuForMobileDevices>
      </NavigationContainer>

      {stateMenuOpen ? (
        <BackDropMenu>
          <animated.div style={slideanimation}>
            <SliceMenu setOpen={onClickHandlerMenuOpen} />
          </animated.div>
        </BackDropMenu>
      ) : null}

      <BackgroundNavigationMobile mobileMenu={stateMobileMenuOpen} />
      {stateMobileMenuOpen ? (
        <MobileMenuContainer>
          <LinkButton
            to={"/"}
            color={"white"}
            colorhover={"white"}
            onClick={onClickHandlerMobileMenuOpen}
          >
            <ButtonContainer>Home</ButtonContainer>
          </LinkButton>

          <LinkButton
            to={"reactblog"}
            color={"white"}
            colorhover={"#6D4092"}
            onClick={onClickHandlerMobileMenuOpen}
          >
            <MenuIcons reac={true} />
          </LinkButton>

          <LinkButton
            to={"javascriptblog"}
            color={"white"}
            colorhover={"yellow"}
            onClick={onClickHandlerMobileMenuOpen}
          >
            <MenuIcons javaScript={true} />
          </LinkButton>

          <LinkButton
            to={"javablog"}
            color={"white"}
            colorhover={"#854747"}
            onClick={onClickHandlerMobileMenuOpen}
          >
            <MenuIcons java={true} />
          </LinkButton>
        </MobileMenuContainer>
      ) : null}
    </>
  );
}
export default Navigation;
