import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary.js";
import Navigation from "./components/navigation/Navigation.js";

const Main = React.lazy(() => import("./components/main/Main.js"));
const ReactBlog = React.lazy(() =>
  import("./components/reactBlog/ReactBlog.js")
);
const JavaScriptBlog = React.lazy(() =>
  import("./components/javaScriptBlog/JavaScriptBlog.js")
);
const JavaBlog = React.lazy(() => import("./components/javaBlog/JavaBlog.js"));

/***************
 * App component
 ***************/
function App() {
  return (
    <>
      <ErrorBoundary>
        <Navigation />
        <Suspense
          fallback={
            <div style={{ width: "100%", textAlign: "center" }}>Loading...</div>
          }
        >
          <Routes>
            <Route path="/" element={<Main />} />

            <Route path="reactblog" element={<ReactBlog />} />
            <Route path="javascriptblog" element={<JavaScriptBlog />} />
            <Route path="javablog" element={<JavaBlog />} />

            <Route
              path="*"
              element={
                <main
                  style={{
                    padding: "1rem",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "3rem",
                  }}
                >
                  <p>Invalid path</p>
                </main>
              }
            />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}

export default App;
