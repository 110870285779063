import styled, { keyframes } from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  background: linear-gradient(black , tomato, white);
`;

// exported to FadeInAnimation.js
// container holds TextFadeAnimation
export const ShowTextMain = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  font-size: 3rem;
  margin-bottom: 20%;
  /* border: 2px red solid; */
  @media only screen and (max-width: 700px){
    font-size: 2.5rem;
   
  }
`;

export const TextFadeAnimation = styled.div`
  width: auto;
  height: auto;
  text-align: center;
  font-family: 'Square Peg', cursive;
  color: white;
  text-shadow: 3px ${(props) => props.number} 4px gray;
  cursor: pointer;
`;

// exported to Main.js
export const BackDropMenu = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 2000;
  background-color: rgba(236, 240, 241, 0.1);
  @media only screen and (max-width: 700px) {
    width: 0;
    height: 0;
    z-index: 0;
  }
`;

// Footer animation
const footerAnimation = keyframes` 
  from{
    transform: translateY(100%);
  }
  to{
    transform: translateY(0%);
  }
`;

// Footer exported to Main.js
export const Footer = styled.div`
  width: 100%;
  height: auto;
  bottom: 0;
  left: 0;
  font-size: 1.2rem;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: black;
  z-index: 1000;
  position: absolute;
  animation: ${footerAnimation} 0.3s;
  @media only screen and (max-width: 700px){
    font-size: 1rem;
  }

`;
