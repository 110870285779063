import React from "react";
import { ButtonContainer } from "../sliceMenu/slicemenu-style-component";

/***************************
 * UI menu for mobile and web
 * exported to sliceMenu and navigation components
 **************************/
export default function MenuIcons({ reac, javaScript, java }) {
  return (
    <>
      {reac ? (
        <ButtonContainer>
          React • Angular • Git • PWA • Firebase • Unity
        </ButtonContainer>
      ) : null}
      {javaScript ? (
        <ButtonContainer>
          JavaScript • TypeScript • C#
        </ButtonContainer>
      ) : null}
      {java ? (
        <ButtonContainer>
          Java • SQL • Spring • Agile • AWS
        </ButtonContainer>
      ) : null}
    </>
  );
}
