import styled, { css } from "styled-components";

// other stand for other components besides the main page for background porpuses
export const NavigationContainer = styled.div`
  width: 100%;
  height: 11vh;
  display: flex;
  border: 0;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(black 100%, tomato);
`;

// adding gradient to a text
export const TitleNavigation = styled.div`
  width: auto;
  height: auto;
  text-align: center;
  font-size: 1.9rem;
  color: white;
  margin-left: 1.5rem;
  /* border: 2px solid red; */
  background: -webkit-linear-gradient(left, #7b7d7d, white);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

/***************************
 * button for non mobile devices
 ***************************/
export const ButtonNavigationContainer = styled.div`
  width: 20%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  @media only screen and (max-width: 700px) {
    top: -100%;
    right: -100%;
    position: absolute;
  }
`;
export const ButtonNavigationSubject = styled.button`
  width: auto;
  height: auto;
  background: transparent;
  color: white;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  border: 0;
  text-transform: uppercase;
  transition: all 0.8s ease;
  box-shadow: none;
  /* border: 2px solid white; */
  &:hover {
    background: none;
    color: tomato;
    transition: all 0.8s ease;
  }
`;

/***************************
 * button Navigation for mobile devices
 * exported to Navigation.js
 ***************************/
const lineTopBottom = css`
  width: 5vh;
  height: 2px;
  background: white;
  border-radius: 2rem;
  @media only screen and (max-height: 400px) {
    width: 10vh;
  }
`;
const lineMiddle = css`
  width: 4vh;
  height: 2px;
  background: white;
  border-radius: 2rem;
  @media only screen and (max-height: 400px) {
    width: 8vh;
  }
`;

// received props to control close button animation
/* exported to Navigation.js */
export const One = styled.div`
  ${lineTopBottom}
  background: ${(props) => (props.open ? "red" : null)};
  transform: ${(props) =>
    props.open ? "rotate(55deg) scale(1.6, 2.2)" : "rotate(0deg)"};
  transition: transform 1s ease;
`;
export const Two = styled.div`
  ${lineMiddle}
  position: absolute;
  margin-left: 0.1rem;
  background: ${(props) => (props.open ? "transparent" : "white")};
  transition: all 1s ease;
`;
// margin-top: 0 bring the third line to show in the animation
export const Three = styled.div`
  ${lineTopBottom}
  margin-top: ${(props) => (props.open ? "0" : ".9rem")};
  background: ${(props) => (props.open ? "linear-gradient(red, black)" : null)};
  transform: ${(props) =>
    props.open ? "rotate(-55deg) scale(1.6, 2.7)" : "rotate(0deg)"};
  transition: transform 1s ease;
`;

// container to hold the lines
// exported to Navigation.js
export const NavigationMenuForMobileDevices = styled.div`
  width: 0;
  height: 0;
  top: -20%;
  left: 110%;
  position: absolute; // absolute makes the container to dissapear
  z-index: 0;
  @media only screen and (max-width: 700px) {
    top: 3%;
    left: 87%;
    height: ${(props) => (props.open ? "4vh" : "6vh")};
    width: 10%;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    z-index: 1300;
    /* border: 2px white solid; */
    cursor: pointer;
  }
`;

// exported to Main.js
// mobile background
export const BackgroundNavigationMobile = styled.div`
  height: 0;
  width: 0;
  @media only screen and (max-width: 700px) {
    width: 1rem;
    height: 1rem;
    top: 5%;
    left: 85%;
    border-radius: 55%;
    z-index: 1200;
    position: fixed;
    background: linear-gradient(black 50%, white);
    transition: transform 0.3s cubic-bezier(0.23, 0.02, 0.67, 1.11);
    transform: ${(props) => (props.mobileMenu ? "scale(150)" : "scale(0)")};
  }
`;

// container to hold the buttons
// exported to Main.js
export const MobileMenuContainer = styled.div`
  /* makes the container to disappear */
  top: -20%;
  width: 0;
  height: 0;
  @media only screen and (max-width: 700px) {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1200;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
  }
`;
