import styled, { css, keyframes } from "styled-components";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";

/****************************************
 * slice  menu for non mobile devices
 ****************************************/
export const SliceMenuContainer = styled.div`
  width: 40%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  border: 0;
  outline: none;
  border-style: outset;
  padding: 1.5rem;
  background: linear-gradient(0.75turn, #424949 2%, white);
  @media only screen and (max-width: 700px) {
    width: 0;
    height: 0;
    z-index: 0;
    padding: 0;
  }
`;

/***********************************
 * Button animation
 ***********************************/
const buttonAnimation = keyframes` 
  from{
    transform: translateY(-200%);
  }
  to{
    transform: translateY(0%);
  }
`;

/***********************************
 * Home Button issued for slicemenu
 ***********************************/
const LinkStyle = css`
  width: 90%;
  height: 7%;
  border-radius: 1rem;
  margin: 1rem 0;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  text-align: center;
  box-shadow: 0px 0px 2px gray;

  animation: ${buttonAnimation} 0.7s;
  &:hover {
    box-shadow: none;
    background: linear-gradient(#a6acaf, gray);
    transition: all 0.6s ease;
  }
  &:active {
    transform: translateY(2px);
    transition: transform 0.2s;
  }
  @media only screen and (max-height: 450px){
    width: 99%;
    height: 11%;
  }
`;

export const LinkButton = styled(Link)`
  ${LinkStyle}
  color: ${(props) => props.color};
  &:hover {
    color: ${(props) => props.colorhover};
  }
`;

export const NavButton = styled.div`
  ${LinkStyle}
  color: ${(props) => props.color};
  &:hover {
    color: ${(props) => props.colorhover};
  }
`;

/***********************************
 * ButtonConatiner controls the font for each button
 ***********************************/
export const ButtonContainer = styled.div`
  width: 95%;
  height: 95%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.1rem;
  /* border: 2px solid red; */
  @media only screen and (max-height: 450px){
    width: 95%;
    font-size: .6rem;
  }
  @media only screen and (max-width: 750px){
    font-size: 1rem;
  }
`;


////////////////////////////////////////////////
// icon and container for slice menu
// exported to SliceMenu.js, animation to a circle
export const CloseMenuContainer = styled.div`
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3rem;
  /* border: 2px white solid; */
`;

// x icon for the slice menu
export const CloseMenu = styled(MdClose)`
  width: 10vh;
  height: 5vh;
  color: tomato;
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.6s ease;
  &:hover {
    color: white;
    background: tomato;
    box-shadow: 2px 3px 4px gray;
    border-radius: 70%;
    transform: rotate(180deg) scale(.5, .9);
    transition: all 0.6s ease;
  }
`;
