import { createSlice } from "@reduxjs/toolkit";

export const showPageStateSlice = createSlice({
  name: "showpage",
  initialState: {
    currentName: null,
    open: false,
    mobileOpen: false,
  },
  reducers: {
    changeCurrentName: (state, action) => {
      state.currentName = action.payload.currentname;
    },
    changeMenuOpen: (state, action) => {
      state.open = action.payload.currentStateOpen;
    },
    changeMobileOpen: (state, action) => {
      state.mobileOpen = action.payload.currentStateMobileOpen;
    },
  },
});
export const { changeCurrentName, changeMenuOpen, changeMobileOpen } =
  showPageStateSlice.actions;
export const selectcurrentName = (state) => state.showpage.currentName;
export const selectcurrentMenuOpen = (state) => state.showpage.open;
export const selectcurrentMobileOpen = (state) => state.showpage.mobileOpen;
export default showPageStateSlice.reducer;
